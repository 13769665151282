/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isEmpty, has } from 'lodash';
import DropdownField from './DropdownField';
import FreeTextField from './FreeTextField';
import SitecodeField from './SitecodeField';
import AddressField from './AddressField';
import {
  setUpKeys,
  openMedirecords,
  verifyForm,
  getClassifiedFields,
  getTemplate,
  getContactDetails,
} from '../../utils/psoFormUtils';
import { MANDATORY_STRING, OPTIONAL_STRING } from '../../constants';

const PSO_FORM_STATES = {
  isReadOnly: 'isReadOnly',
  isEditBtnReadOnly: 'isEditBtnReadOnly',
  isEdit: 'isEdit',
  isSubmitClicked: 'isSubmitClicked',
  isAppointmentBtnClicked: 'isAppointmentBtnClicked',
};

const initialPSOFormStatus = {
  isReadOnly: true,
  isEditBtnReadOnly: false,
  isEdit: true,
  isSubmitClicked: false,
  isAppointmentBtnClicked: false,
};

const PsoForm = ({
  messageApi,
  setUnsaved,
  sitecodeList,
  selectedPatient,
  setFormUpdated,
  clearPsoForm,
  updatePsoForm,
  isSavingPatient,
  isSavingPatientSuccess,
  isSavingPatientError,
}) => {
  const [form, setForm] = useState({ ...selectedPatient });
  console.log('selectedPatient', selectedPatient);
  console.log('form', form);
  const [contactDetails, setContactDetails] = useState({});

  const [formLoading, setFormLoading] = useState(false);
  const [template, setTemplate] = useState({});
  const [fixedFieldTypes, setFixedFieldTypes] = useState();
  const [customUITypes, setCustomUITypes] = useState({});
  const [dropdownList, setDropdownList] = useState({
    clientType: ['-Select-'],
    sitecode: [],
    callType: ['-Select-'],
    MMEXUploaded: ['-Select-'],
    abnormalResult: ['-Select-'],
    category: ['-Select-'],
    triageCAT: ['-Select-'],
    PHNOutofHours: ['-Select-'],
  });
  const [generalDropdownKeys, setGeneralDropdownKeys] = useState([]);
  const [dropdownKeys, setDropdownKeys] = useState([]);
  const [textKeys, setTextKeys] = useState([]);
  const [bigTextKeys, setBigTextKeys] = useState([]);
  const [selectedSitecode, setSelectedSitecode] = useState(
    form['sitecode'] || ''
  );

  // const sitecodeRef = useRef();
  const [requiredFields, setRequiredFields] = useState([]);
  const [classifiedFields, setClassifiedFields] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [medirecordMessage, setMedirecordMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [formMessage, setFormMessage] = useState('');

  const [psoFormStatus, setPsoFormStatus] = useState({
    ...initialPSOFormStatus,
  });

  useEffect(() => {
    if (psoFormStatus?.isSubmitClicked && !psoFormStatus?.isReadOnly) {
      verifyForm(
        psoFormStatus?.isSubmitClicked,
        requiredFields,
        form,
        handleErrorMessage
      );
    }
  }, [form, requiredFields, psoFormStatus]);

  useEffect(() => {
    if (psoFormStatus?.isSubmitClicked && !psoFormStatus?.isReadOnly) {
      verifyForm(
        psoFormStatus?.isSubmitClicked,
        requiredFields,
        form,
        handleErrorMessage
      );
    }
  }, [form, requiredFields, psoFormStatus]);

  useEffect(() => {
    if (psoFormStatus?.isEdit === true) {
      setUnsaved((prev) => ({ ...prev, psoForm: false }));
    } else if (psoFormStatus?.isEdit === false) {
      setUnsaved((prev) => ({ ...prev, psoForm: true }));
    }
  }, [psoFormStatus.isEdit, setUnsaved]);

  useEffect(() => {
    getClassifiedFields(
      sitecodeList,
      fixedFieldTypes,
      setFixedFieldTypes,
      customUITypes,
      setClassifiedFields,
      setDropdownList
    );
  }, [selectedSitecode, customUITypes]);

  useEffect(() => {
    if (!isEmpty(classifiedFields)) {
      setUpKeys(
        template,
        Object.keys(template),
        setGeneralDropdownKeys,
        setDropdownKeys,
        setTextKeys,
        setBigTextKeys,
        classifiedFields
      );
    }
  }, [template, classifiedFields]);

  useEffect(() => {
    getTemplate({
      selectedSitecode,
      setRequiredFields,
      setTemplate,
      setForm,
      setCustomUITypes,
      setFormMessage,
      setFormLoading,
    });
    getContactDetails(selectedSitecode, setContactDetails);
  }, [selectedSitecode]);

  useEffect(() => {
    if (!isSavingPatient && isSavingPatientSuccess && !isSavingPatientError) {
      messageApi.open({
        type: 'success',
        content: 'Successful save PSO form',
        duration: 1,
      });
      setPsoFormStatus((prev) => ({
        ...prev,
        [PSO_FORM_STATES.isReadOnly]: true,
        [PSO_FORM_STATES.isEdit]: true,
      }));
      setFormUpdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingPatient, isSavingPatientSuccess, isSavingPatientError]);

  useEffect(() => {
    return () => {
      clearPsoForm();
    };
  }, [clearPsoForm]);

  const handleErrorMessage = (errorMsg) => {
    setErrorMessage(errorMsg);
  };

  const handleChange = (e, key) => {
    let value = '';
    if (key === 'address') {
      if (has(e, 'description')) {
        value = e.description;
      } else if (has(e, 'target') && e.target.value) {
        value = e.target.value;
      } else if (form?.['address']) {
        const formAddress = form['address'];
        if (
          formAddress.includes(MANDATORY_STRING) ||
          formAddress.includes(OPTIONAL_STRING)
        ) {
          value = formAddress?.split('/')?.[1] ?? '';
        } else {
          value = e;
        }
      } else {
        value = (e || form?.['address']) ?? null;
      }
    } else {
      value = e.target.value;
    }
    setForm({ ...form, [key]: value });
  };

  const handleDropdownChange = (value, key) => {
    if (!psoFormStatus?.isReadOnly && key === 'sitecode') {
      setSelectedSitecode(value);
      handleIsSubmitClicked(false);
      setErrorMessage({});
    } else {
      setForm({
        ...form,
        [key]: value,
      });
    }
  };

  const handleStateChange = (value, targetField) => {
    setPsoFormStatus((prev) => ({ ...prev, [targetField]: value }));
  };

  const handleIsEdit = (value) => {
    handleStateChange(value, PSO_FORM_STATES.isEdit);
  };

  const handleIsEditBtnReadOnly = (value) => {
    handleStateChange(value, PSO_FORM_STATES.isEditBtnReadOnly);
  };

  const handleIsReadOnly = (value) => {
    handleStateChange(value, PSO_FORM_STATES.isReadOnly);
  };

  const handleIsSubmitClicked = (value) => {
    handleStateChange(value, PSO_FORM_STATES.isSubmitClicked);
  };

  const handleIsAppointmentBtnClicked = (value) => {
    handleStateChange(value, PSO_FORM_STATES.isAppointmentBtnClicked);
  };

  const editForm = () => {
    handleIsReadOnly(false);
    handleIsEdit(false);
  };

  const saveForm = () => {
    handleIsSubmitClicked(true);
    const err = verifyForm(true, requiredFields, form, handleErrorMessage);

    if (!isEmpty(err)) return;

    const caseID = form.caseID ?? selectedPatient.caseID;
    const [callSid, patientIndex] = caseID.split('-');

    updatePsoForm({
      ...form,
      callSid,
      patientIndex,
    });
  };

  return (
    <div>
      {formLoading && <Skeleton active />}
      {(!isEmpty(form) &&
        (generalDropdownKeys.length > 0 || dropdownKeys.length > 0) &&
        !formLoading) ||
      (!isEmpty(form) &&
        (generalDropdownKeys.length > 0 || dropdownKeys.length > 0) &&
        form['patientFirstName'] &&
        form['patientFirstName'] !== 'undefined' &&
        form['patientFirstName'] !== MANDATORY_STRING &&
        !formLoading) ? (
        <Form layout="vertical" size="small">
          {generalDropdownKeys.length > 0 &&
            generalDropdownKeys.map((row, index) => (
              <Row gutter={32} key={index}>
                {row.map((item, i) => {
                  if (item === 'sitecode') {
                    return (
                      <Col key={`${item}-${i}`} span={12}>
                        <SitecodeField
                          i={i}
                          selectedSitecode={selectedSitecode}
                          setSelectedSitecode={setSelectedSitecode}
                          isReadOnly={psoFormStatus?.isReadOnly}
                          form={form}
                          handleDropdownChange={handleDropdownChange}
                          errorMessage={errorMessage}
                          dropdownList={dropdownList}
                        />
                      </Col>
                    );
                  }
                  return (
                    <Col key={`${item}-${i}`} span={12}>
                      <DropdownField
                        item={item}
                        key={i}
                        i={i}
                        isReadOnly={psoFormStatus?.isReadOnly}
                        form={form}
                        handleDropdownChange={handleDropdownChange}
                        errorMessage={errorMessage}
                        dropdownList={dropdownList}
                      />
                    </Col>
                  );
                })}
              </Row>
            ))}
          {dropdownKeys.length > 0 &&
            dropdownKeys.map((row, index) => (
              <Row gutter={32} key={`dropdownKeys-${index}`}>
                {row.map((item, i) => {
                  return (
                    <Col span={12} key={`${item}-${i}`}>
                      <DropdownField
                        item={item}
                        i={i}
                        isReadOnly={psoFormStatus?.isReadOnly}
                        form={form}
                        handleDropdownChange={handleDropdownChange}
                        errorMessage={errorMessage}
                        dropdownList={dropdownList}
                      />
                    </Col>
                  );
                })}
              </Row>
            ))}
          {has(form, 'address') && form['address'] !== null && (
            <Col span={24}>
              <AddressField
                handleChange={handleChange}
                errorMessage={errorMessage}
                isReadOnly={psoFormStatus?.isReadOnly}
                form={form}
              />
            </Col>
          )}
          {textKeys.length > 0 &&
            textKeys.map((row, index) => (
              <Row gutter={32} key={`textKeys-${index}`}>
                {row.map((item, i) => {
                  return (
                    <Col span={12} key={`${item}-${i}`}>
                      <FreeTextField
                        item={item}
                        key={i}
                        i={i}
                        isReadOnly={psoFormStatus?.isReadOnly}
                        form={form}
                        handleChange={handleChange}
                        classifiedFields={classifiedFields}
                        errorMessage={errorMessage}
                      />
                    </Col>
                  );
                })}
              </Row>
            ))}
          {bigTextKeys.length > 0 &&
            bigTextKeys.map((bigTextKey, i) => {
              return (
                // <Row gutter={32} key={`bigTextKeys-${i}`}>
                <Col span={24} key={`bigTextKeys-${i}`}>
                  <FreeTextField
                    item={bigTextKey}
                    i={i}
                    isReadOnly={psoFormStatus?.isReadOnly}
                    form={form}
                    handleChange={handleChange}
                    classifiedFields={classifiedFields}
                    errorMessage={errorMessage}
                  />
                </Col>
              );
            })}

          {loading && (
            <div>
              <LoadingOutlined spin />
            </div>
          )}
          <div
            style={{ display: 'flex', justifyContent: 'flex-start', gap: 20 }}
          >
            {psoFormStatus?.isEdit ? (
              <Button
                size="middle"
                color="primary"
                onClick={editForm}
                disabled={!!psoFormStatus?.isEditBtnReadOnly}
                loading={isSavingPatient}
              >
                Edit
              </Button>
            ) : (
              <Button
                type="primary"
                size="middle"
                onClick={saveForm}
                disabled={
                  psoFormStatus?.isReadOnly || textKeys.length === 0
                    ? true
                    : false
                }
                loading={isSavingPatient}
              >
                Save
              </Button>
            )}
            <Button
              size="middle"
              type="primary"
              onClick={() =>
                openMedirecords(
                  form,
                  contactDetails?.mediRecordsDoctor,
                  setMedirecordMessage,
                  setLoading,
                  handleIsEditBtnReadOnly,
                  handleIsAppointmentBtnClicked
                )
              }
              disabled={
                !psoFormStatus?.isReadOnly ||
                !psoFormStatus?.isEdit ||
                psoFormStatus?.isAppointmentBtnClicked ||
                loading
              }
            >
              Appointment
            </Button>
          </div>
          {medirecordMessage && <div>{medirecordMessage}</div>}
        </Form>
      ) : (
        <h3>{formMessage}</h3>
      )}
    </div>
  );
};

export default PsoForm;
