import axios from 'axios';

// TODO: Add PROD URL, and move function keys to ENV variables/get token
export const searchPatients = async (filters) => {
  try {
    let res = await axios.post(
      'https://med-discrete-data-uat.azurewebsites.net/api/searchPatients?code=fmauB---H7eOustrnKCYE5tZGF0LKKOd-x_QADDB3KscAzFuNnRG0g==',
      filters
    );
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};

export const savePatientPsoForm = async (data) => {
  try {
    let res = await axios.post(
      'https://med-discrete-data-uat.azurewebsites.net/api/updateNotesByCallSid?code=sKbyHk5nYjUauM6SrvDEOJWyFt-W1fCZ7O1uPwAptpabAzFu59YmKw==',
      data
    );
    return res;
  } catch (err) {
    console.error('err', err);
  }
};

export const fetchPatientExtendedNote = async (caseID) => {
  console.log('caseID', caseID);
  if (caseID) {
    try {
      let res = await axios.post(
        'https://extended-note-uat.azurewebsites.net/api/getExtendedNoteBycaseID?code=E2T0s77WaEn6Ls8E6KwWvH79PQ4r2TSRzlLSJSchY7oVAzFuptDGOQ==',
        { caseID }
      );
      return res;
    } catch (err) {
      console.error('err', err);
    }
  } else {
    throw new Error('caseID is required');
  }
};

export const savePatientExtendedNote = async (data) => {
  const { callSid } = data;
  console.log('callSid', callSid);
  if (callSid) {
    try {
      let res = await axios.post(
        'https://extended-note-uat.azurewebsites.net/api/updateExtendedNote?code=xrjVbOW-cPH9ONtkEtATEkry6ZksaT_SSKzMXgCTaEHBAzFuEa1aiA==',
        data
      );
      return res;
    } catch (err) {
      console.error('err', err);
    }
  } else {
    throw new Error('callSid is required');
  }
};

export const updateLinkCallByCaseID = async (data) => {
  const { caseIDOriginal } = data;
  if (caseIDOriginal) {
    try {
      let res = await axios.post(
        'https://med-discrete-data-uat.azurewebsites.net/api/updateLinkCallByCaseID?code=5jFjrCjDb_xmugamwRbrlJ-lcUr_Ddqyd5mBnsnV4AvwAzFujXIy_g%3D%3D',
        data
      );
      return res.data;
    } catch (err) {
      console.error('err', err);
    }
  } else {
    throw new Error('caseID is required');
  }
};

export const fetchActiveCallsFromTable = async () => {
  try {
    let res = await axios.get(
      'https://med-discrete-data-uat.azurewebsites.net/api/getActiveCalls?code=eAd-zwgve6xSFibFYHFMp3eDCEpSP9LRSh0zMCUrXbcNAzFuiWWt8w=='
    );
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};

export const fetchAppointmentsMR = async () => {
  try {
    let res = await axios.get(
      'https://med-discrete-data-uat.azurewebsites.net/api/getMediRecordAppointments?code=77cjPmIDJHljOaE2s81dFkoVAn94t5l9C5K1MFhcT0XkAzFulCWCKw=='
    );
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};
