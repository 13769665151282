import React, { useEffect } from 'react';
import { message } from 'antd';
import { usePatientStore } from '../store';
import Header from './Layout/Header';
import PatientTable from './Table/PatientTable';
import ModalService from './Modal/ModalService';

const PSOPanel = ({ userInfo }) => {
  const [messageApi, messagePlaceholder] = message.useMessage();

  const {
    isFetchingPatients,
    isFetchingPatientsSuccess,
    isFetchingPatientsError,
    isFetchingMRAppointments,
    isFetchingMRAppointmentsSuccess,
    isFetchingMRAppointmentsError,
  } = usePatientStore((state) => ({
    isFetchingPatients: state.isFetchingPatients,
    isFetchingPatientsSuccess: state.isFetchingPatientsSuccess,
    isFetchingPatientsError: state.isFetchingPatientsError,
    isFetchingMRAppointments: state.isFetchingMRAppointments,
    isFetchingMRAppointmentsSuccess: state.isFetchingMRAppointmentsSuccess,
    isFetchingMRAppointmentsError: state.isFetchingMRAppointmentsError,
  }));

  useEffect(() => {
    if (
      !isFetchingPatients &&
      !isFetchingPatientsSuccess &&
      isFetchingPatientsError
    ) {
      messageApi.open({
        type: 'error',
        content: 'Loading calls failed',
        duration: 1,
      });
    }
    if (
      !isFetchingPatients &&
      isFetchingPatientsSuccess &&
      !isFetchingPatientsError
    ) {
      messageApi.open({
        type: 'success',
        content: 'Calls loaded',
        duration: 1,
      });
    }
  }, [
    isFetchingPatients,
    isFetchingPatientsError,
    isFetchingPatientsSuccess,
    messageApi,
  ]);

  useEffect(() => {
    if (
      !isFetchingMRAppointments &&
      !isFetchingMRAppointmentsSuccess &&
      isFetchingMRAppointmentsError
    ) {
      messageApi.open({
        type: 'error',
        content: 'Loading appointments failed',
        duration: 1,
      });
    }
    if (
      !isFetchingMRAppointments &&
      isFetchingMRAppointmentsSuccess &&
      !isFetchingMRAppointmentsError
    ) {
      messageApi.open({
        type: 'success',
        content: 'Appointments loaded',
        duration: 1,
      });
    }
  }, [
    isFetchingMRAppointments,
    isFetchingMRAppointmentsError,
    isFetchingMRAppointmentsSuccess,
    messageApi,
  ]);

  return (
    <>
      {messagePlaceholder}
      <Header />
      {isFetchingPatientsError ? (
        <div>
          Error: Failed to get call information, please try again or contact our
          technical support.
        </div>
      ) : (
        <>
          <ModalService messageApi={messageApi} />
          <PatientTable userInfo={userInfo} messageApi={messageApi} />
        </>
      )}
    </>
  );
};

export default PSOPanel;
